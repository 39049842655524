import { SubmitInitiator, useCashierArgs } from './useCashierArgs';

export const useProcessingInitiator = () => {
  const {
    cashierArgs: { submitInitiator },
  } = useCashierArgs();

  const isCashierInitiator = submitInitiator === SubmitInitiator.self;
  const isPlatformInitiator = submitInitiator === SubmitInitiator.parent;

  return {
    isCashierInitiator,
    isPlatformInitiator,
  };
};
