import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import {
  commonMiddleware,
  communicationTasksMiddleware,
  confirmationMiddleware,
  dataPreprocessingMiddleware,
  paymentInfoMiddleware,
  PaymentsClient,
  paymentsEpics,
  platformProcessingInitiatorMiddleware,
  rootReducer,
  transactionProcessingMiddleware,
} from '@cashier/cashier-common';

import { environment } from '../environments/environment';
import { getAppRouter } from './elements/routes';

const Payments = new PaymentsClient(environment.paymentGatewayApiUrl);

const paymentsEpic = combineEpics(paymentsEpics);

const epicMiddleWare = createEpicMiddleware({
  dependencies: { Api: Payments },
});

const store = configureStore({
  middleware: [
    epicMiddleWare,
    commonMiddleware,
    transactionProcessingMiddleware,
    communicationTasksMiddleware,
    dataPreprocessingMiddleware,
    paymentInfoMiddleware,
    confirmationMiddleware,
    platformProcessingInitiatorMiddleware,
  ],
  reducer: rootReducer,
});

epicMiddleWare.run(paymentsEpic);

export const App = () => (
  <Provider store={store}>
    <RouterProvider router={getAppRouter()} />
  </Provider>
);
