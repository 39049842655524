import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum DynamicScreens {
  finalScreen = 'finalScreen',
  processingResult = 'processingResult',
  paymentForm = 'paymentForm',
  confirmationForm = 'confirmationForm',
}

export interface CustomerInformation {
  activeScreen: DynamicScreens;
  amount?: string;
  currency: string;
  bonusCode: string;
  termsAgreement: {
    isCheckboxVisible: boolean;
    isChecked: boolean;
  };
}

export const CUSTOMER_INFORMATION_KEY = 'customerInformation';

export const customerInformationInitialState: CustomerInformation = {
  amount: null,
  activeScreen: DynamicScreens.paymentForm,
  currency: 'EUR',
  bonusCode: '',
  termsAgreement: {
    isCheckboxVisible: false,
    isChecked: false,
  },
};

export const customerInformationSlice = createSlice({
  name: CUSTOMER_INFORMATION_KEY,
  initialState: customerInformationInitialState,
  reducers: {
    setActiveScreen: (state, { payload }: PayloadAction<DynamicScreens>) => {
      state.activeScreen = payload;
    },
    setAmount: (state, { payload }: PayloadAction<string>) => {
      state.amount = payload;
    },
    setCurrency: (state, { payload }: PayloadAction<string>) => {
      state.currency = payload;
    },
    setBonusCode: (state, { payload }: PayloadAction<string>) => {
      state.bonusCode = payload;
    },
    setTermsAgreementCheckbox: (
      state,
      {
        payload,
      }: PayloadAction<{ isCheckboxVisible: boolean; isChecked: boolean }>,
    ) => {
      state.termsAgreement.isCheckboxVisible = payload.isCheckboxVisible;
      state.termsAgreement.isChecked = payload.isChecked;
    },
  },
});

export const customerInformationReducer = customerInformationSlice.reducer;
export const {
  setActiveScreen,
  setAmount,
  setCurrency,
  setBonusCode,
  setTermsAgreementCheckbox,
} = customerInformationSlice.actions;
