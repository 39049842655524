import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Theme, TransactionType } from '../../interfaces';

enum Directions {
  Ltr = 'ltr',
  Rtl = 'rtl',
}

export enum SubmitInitiator {
  self = 'self',
  parent = 'parent',
}

export interface CashierConfigArgs {
  userId: string;
  sessionId: string;
  merchantId: string;
  activeType: TransactionType;
  currency: string;
  bonusCode: string;
  termsUrl: string;
  platform: string;
  hostUrl: string;
  language: string;
  accountId: string;
  pgwMerchantId: string;
  cashierHostLocation: string;
  customerBalance: string;
  webView: string | boolean;
  direction: Directions;
  country: string;
  orderId: string;
  amount: string;
  windowExist: boolean;
  theme: Theme;
  submitInitiator: SubmitInitiator;
}

export const CASHIER_CONFIG_FEATURE_KEY = 'cashier_config';

export const initialCashierConfigState: CashierConfigArgs = {
  userId: '',
  sessionId: '',
  merchantId: '',
  activeType: null,
  currency: '',
  bonusCode: '',
  termsUrl: '',
  platform: '',
  hostUrl: '',
  language: '',
  accountId: '',
  pgwMerchantId: '',
  cashierHostLocation: '',
  customerBalance: '',
  webView: '',
  direction: Directions.Ltr,
  country: '',
  orderId: '',
  amount: '',
  windowExist: false,
  theme: Theme.Dark,
  submitInitiator: SubmitInitiator.self,
};

export const cashierConfigSlice = createSlice({
  name: CASHIER_CONFIG_FEATURE_KEY,
  initialState: initialCashierConfigState,
  reducers: {
    setCashierConfig: (state, action: PayloadAction<CashierConfigArgs>) => {
      state = action.payload;
      return state;
    },
  },
});

export const cashierConfigReducer = cashierConfigSlice.reducer;

export const { setCashierConfig } = cashierConfigSlice.actions;
