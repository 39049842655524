import { TransactionType } from '../interfaces';
import { TransactionStates } from '../slices';

const isTransactionSuccess = (type: string, state: string): boolean => {
  if (TransactionType.Deposit === type) {
    return TransactionStates.SUCCESSFUL === state;
  }

  if (TransactionType.Withdrawal === type) {
    return (
      TransactionStates.PROCESSING === state ||
      TransactionStates.SUCCESSFUL === state
    );
  }

  return false;
};

const isTransactionPending = (
  type: TransactionType,
  state: string,
): boolean => {
  if (TransactionType.Deposit === type) {
    return TransactionStates.PROCESSING === state;
  }

  return false;
};

const isTransactionSuccessOrPending = (
  type: TransactionType,
  state: string,
): boolean => {
  return isTransactionSuccess(type, state) || isTransactionPending(type, state);
};

export {
  isTransactionSuccess,
  isTransactionPending,
  isTransactionSuccessOrPending,
};
