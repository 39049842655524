import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { convertToLocale } from '../helpers';
import { Theme, TransactionType } from '../interfaces';
import { CashierInitParams } from '../models';
import { selectCashierConfig, setCashierConfig } from '../slices';

export enum Directions {
  Ltr = 'ltr',
  Rtl = 'rtl',
}

export enum SubmitInitiator {
  self = 'self',
  parent = 'parent',
}

export interface CashierConfigArgs {
  userId: string;
  sessionId: string;
  merchantId: string;
  activeType: TransactionType;
  currency: string;
  bonusCode: string;
  termsUrl: string;
  platform: string;
  hostUrl: string;
  language: string;
  accountId: string;
  pgwMerchantId: string;
  cashierHostLocation: string;
  customerBalance: string;
  webView: boolean;
  country: string;
  direction: Directions.Rtl | Directions.Ltr;
  orderId: string;
  amount: string;
  windowExist: boolean;
  theme: Theme;
  submitInitiator: SubmitInitiator;
}

const initialCashierArgs: CashierConfigArgs = {
  activeType: null,
  accountId: '',
  bonusCode: '',
  currency: '',
  hostUrl: '',
  language: '',
  merchantId: '',
  pgwMerchantId: '',
  platform: '',
  sessionId: '',
  termsUrl: '',
  userId: '',
  cashierHostLocation: '',
  customerBalance: '',
  webView: false,
  direction: Directions.Ltr,
  country: '',
  orderId: '',
  amount: '',
  windowExist: false,
  theme: Theme.Dark,
  submitInitiator: SubmitInitiator.self,
};

interface UseCashierArgs {
  setCashierArguments: () => void;
  cashierArgs: CashierConfigArgs;
}

export const useCashierArgs = (): UseCashierArgs => {
  const dispatch = useDispatch();
  const cashierArgs = useSelector(selectCashierConfig);

  const setCashierArguments = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    const getParam = (key: CashierInitParams): string => {
      return params.get(key.toString()) ?? initialCashierArgs[key];
    };

    dispatch(
      setCashierConfig({
        activeType: getParam(CashierInitParams.PaymentType) as TransactionType,
        accountId: getParam(CashierInitParams.AccountId),
        bonusCode: getParam(CashierInitParams.Bonus),
        currency: getParam(CashierInitParams.Currency),
        hostUrl: getParam(CashierInitParams.Host),
        windowExist: !!getParam(CashierInitParams.window),
        language: convertToLocale(getParam(CashierInitParams.Language)),
        merchantId: getParam(CashierInitParams.MerchantId),
        pgwMerchantId: getParam(CashierInitParams.PgwMerchantId),
        platform: getParam(CashierInitParams.Platform),
        sessionId: getParam(CashierInitParams.SessionId),
        termsUrl: getParam(CashierInitParams.TermsUrl),
        userId: getParam(CashierInitParams.UserId),
        cashierHostLocation: window.location.origin,
        customerBalance: getParam(CashierInitParams.CustomerBalance),
        webView: 'true' === getParam(CashierInitParams.WebView),
        direction: getParam(CashierInitParams.Direction) as Directions,
        country: getParam(CashierInitParams.Country),
        orderId:
          getParam(CashierInitParams.OrderId) &&
          getParam(CashierInitParams.OrderId) !== 'null'
            ? getParam(CashierInitParams.OrderId)
            : '',
        amount:
          getParam(CashierInitParams.Amount) &&
          getParam(CashierInitParams.Amount) !== 'null'
            ? getParam(CashierInitParams.Amount)
            : '',
        theme: getParam(CashierInitParams.Theme) as Theme,
        submitInitiator: getParam(
          CashierInitParams.SubmitInitiator,
        ) as SubmitInitiator,
      }),
    );
  }, [dispatch]);

  return { setCashierArguments, cashierArgs };
};
