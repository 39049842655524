import { CashierCommunicationTasks, executeTask } from '../models';
import {
  changeConfirmationFieldValue,
  DynamicScreens,
  getCertainPaymentMethodSuccess,
  getPaymentInfoSuccess,
  paymentCurrentStateSuccess,
  PaymentMethodInput,
  processPaymentFailure,
  processPaymentSuccess,
  setActiveScreen,
  setConfirmationFormData,
  setPaymentInputs,
  setTermsAgreementCheckbox,
  TransactionStates,
} from '../slices';

export const platformProcessingInitiatorMiddleware =
  (store) => (next) => (action) => {
    const result = next(action);

    if (action.type === getCertainPaymentMethodSuccess.toString()) {
      executeTask(CashierCommunicationTasks.onPaymentFormReady);
    }

    if (
      action.type === setPaymentInputs.toString() ||
      action.type === setTermsAgreementCheckbox.toString()
    ) {
      const {
        accounts: { activeAccountOption },
        customerInformation: { termsAgreement },
        payment_form: { paymentInputs },
      } = store.getState();

      const fieldsInformation = Object.entries(paymentInputs).reduce(
        (acc, [name, field]: [name: string, field: PaymentMethodInput]) => {
          if (name === 'accountId') {
            acc.push({
              name,
              value: activeAccountOption.value,
              isValid: Boolean(activeAccountOption.value),
            });
          } else {
            // todo check amount field on favorite amounts
            acc.push({
              name,
              value: field.value || null,
              isValid: field.isValid || false,
            });
          }

          return acc;
        },
        [],
      );

      const agreementCheckbox = termsAgreement.isCheckboxVisible
        ? [
            {
              name: 'agreementCheckbox',
              value: termsAgreement.isChecked,
              isValid: termsAgreement.isChecked,
            },
          ]
        : [];

      executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, [
        ...fieldsInformation,
        ...agreementCheckbox,
      ]);
    }

    if (
      action.type === changeConfirmationFieldValue.toString() ||
      action.type === setConfirmationFormData.toString()
    ) {
      const {
        confirmationForm: { confirmationData },
      } = store.getState();

      executeTask(
        CashierCommunicationTasks.onPaymentAttributesChanged,
        Object.values(confirmationData),
      );
    }

    if (action.type === paymentCurrentStateSuccess.toString()) {
      if (
        action.payload.state === TransactionStates.SUCCESSFUL ||
        action.payload.state === TransactionStates.FORCE_SUCCESSFUL
      ) {
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 200,
        });
      }

      if (
        action.payload.state === TransactionStates.FAILED ||
        action.payload.state === TransactionStates.CANCELLED ||
        action.payload.state === TransactionStates.FORCE_FAILED
      ) {
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 500,
        });
      }
    }

    if (action.type === getPaymentInfoSuccess.toString()) {
      if (action.payload.state === TransactionStates.SUCCESSFUL) {
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 200,
        });
      }
    }

    if (action.type === processPaymentSuccess.toString()) {
      if (action?.payload?.redirectOutput?.url) {
        const { url, parameters } = action.payload.redirectOutput;

        fetch(url, {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(parameters),
          method: 'POST',
        })
          .then((res) => res.text())
          .then((value) => {
            executeTask(CashierCommunicationTasks.onPaymentProcessed, {
              status: 200,
              data: {
                html: value,
              },
            });
          });
      }

      if (action.payload?.resultAction?.type === 'html') {
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 200,
          data: {
            html: action.payload?.resultAction.output,
          },
        });
      }
    }

    if (action.type === processPaymentFailure.toString()) {
      executeTask(CashierCommunicationTasks.onPaymentProcessed, {
        status: 500,
      });
    }

    if (
      action.type === setActiveScreen.toString() &&
      action.payload === DynamicScreens.confirmationForm
    ) {
      executeTask(CashierCommunicationTasks.onPaymentDataConfirmationInitiated);
    }

    return result;
  };
